import React, { useState } from 'react';
import config from '../config';
import './FormularioIngredientes.css';

const FormularioIngredientes = () => {
  const [ingredientes, setIngredientes] = useState([{ nome: '', quantidade: '' }]);
  const [isLoading, setIsLoading] = useState(false);
  const [resposta, setResposta] = useState(null);

  const handleAddIngrediente = () => {
    setIngredientes([...ingredientes, { nome: '', quantidade: '' }]);
  };

  const handleChange = (index, field, value) => {
    const novosIngredientes = [...ingredientes];
    novosIngredientes[index][field] = value;
    setIngredientes(novosIngredientes);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Preparar os dados para enviar ao webhook
    const dadosParaEnviar = {};
    ingredientes.forEach((item, index) => {
      dadosParaEnviar[`ingrediente${index + 1}`] = item.nome;
      dadosParaEnviar[`quantidade${index + 1}`] = item.quantidade;
    });

    try {
      const response = await fetch(`${config.baseUrl}${config.webhookNutrichef}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dadosParaEnviar),
      });

      const resultado = await response.json();
      console.log('Resposta recebida:', resultado);
      setResposta(resultado[0]); // A resposta está dentro de um array
    } catch (error) {
      console.error('Erro ao enviar os dados:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleNovaReceita = () => {
    setResposta(null);
    setIngredientes([{ nome: '', quantidade: '' }]);
  };

  return (
    <div className="formulario-container">
      {!resposta && !isLoading && (
        <form onSubmit={handleSubmit}>
          <h1 className="titulo-centralizado">Sua Receita em IA</h1>
          {ingredientes.map((item, index) => (
            <div key={index} className="input-container">
              <input
                type="text"
                placeholder={`Ingrediente ${index + 1}`}
                value={item.nome}
                onChange={(e) => handleChange(index, 'nome', e.target.value)}
              />
              <input
                type="text"
                placeholder={`Quantidade ${index + 1}`}
                value={item.quantidade}
                onChange={(e) => handleChange(index, 'quantidade', e.target.value)}
              />
            </div>
          ))}
          <button type="button" onClick={handleAddIngrediente} className="botao-adicionar">
            Adicionar Ingrediente
          </button>
          <button type="submit" className="botao-enviar">Enviar</button>
        </form>
      )}

      {isLoading && (
        <div className="video-container">
        <video
            src="https://www.w3schools.com/html/mov_bbb.mp4"
            autoPlay
            loop
            muted
        />
        </div>
      )}

      {resposta && !isLoading && (
        <div className="resposta-container">
          <h1 className="titulo-centralizado">Sua Receita em IA</h1>
          <h2 className="subtitulo-centralizado">{resposta.nomeDaReceita}</h2>

          {resposta.imagemUrl && (
            <img
              src={resposta.imagemUrl}
              alt="Imagem da Receita"
              className="menor-imagem"
            />
          )}

          <div className="ingredientes-container">
            <h3>Ingredientes:</h3>
            <p>{resposta.receitaCompleta}</p>
          </div>

          <h4 className="nutrientes-titulo">Nutrientes Individuais:</h4>
          {resposta.nutrientesIndividuais && resposta.nutrientesIndividuais.length > 0 ? (
            <table className="tabela-nutrientes">
              <thead>
                <tr>
                  <th>Ingrediente</th>
                  <th>Quantidade</th>
                  <th>Energia (kcal)</th>
                  <th>Proteínas (g)</th>
                  <th>Gorduras (g)</th>
                  <th>Carboidratos (g)</th>
                  <th>Fibras (g)</th>
                </tr>
              </thead>
              <tbody>
                {resposta.nutrientesIndividuais.map((item, idx) => {
                  const partes = item.split('  ').map(parte => parte.trim());
                  const [
                    ingrediente, quantidade, energia,
                    proteinas, gorduras, carboidratos, fibras
                  ] = partes;

                  return (
                    <tr key={idx}>
                      <td>{ingrediente.split(': ')[1] || 'N/A'}</td>
                      <td>{quantidade.split(': ')[1] || 'N/A'}</td>
                      <td>{energia.split(': ')[1] || 'N/A'}</td>
                      <td>{proteinas.split(': ')[1] || 'N/A'}</td>
                      <td>{gorduras.split(': ')[1] || 'N/A'}</td>
                      <td>{carboidratos.split(': ')[1] || 'N/A'}</td>
                      <td>{fibras.split(': ')[1] || 'N/A'}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <p>Nenhum dado nutricional disponível.</p>
          )}

          <h4 className="nutrientes-titulo">Nutrientes Totais:</h4>
          {resposta.nutrientesTotais ? (
            <div className="nutrientes-totais">
              <p>Energia: {resposta.nutrientesTotais.energia} kcal</p>
              <p>Proteínas: {resposta.nutrientesTotais.proteinas} g</p>
              <p>Gorduras: {resposta.nutrientesTotais.gorduras} g</p>
              <p>Carboidratos: {resposta.nutrientesTotais.carboidratos} g</p>
              <p>Fibras: {resposta.nutrientesTotais.fibras} g</p>
            </div>
          ) : (
            <p>Nenhum dado nutricional disponível.</p>
          )}

          <button className="botao-nova-receita" onClick={handleNovaReceita}>Gerar Outra Receita</button>
        </div>
      )}
    </div>
  );
};

export default FormularioIngredientes;
